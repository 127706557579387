.tag {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}
.tagInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.checkMark {
  display: none;
}
.tagSelected {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 5px;
  font-size: 12px;
}
.tagRound .tagSelected {
  border-radius: 50%;
  padding: 4px;
}
.tagFill .tagInput:checked ~ .tagSelected {
  background: var(--deep-purple-1, #e7e1ff);
  border-color: transparent;
}
.tagFill .tagInput:checked ~ .tagSelected .checkMark {
  display: inline-flex;
}

.divider {
  background: #9a9a9a;
  border-radius: 4px;
  margin: 0 5px;
}

.callCard {
  position: absolute;
  z-index: 5;
  top: 10px;
  right: 10px;
  width: 520px;
  background: #151515;
  color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}
.callCard .client {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}
.labelText {
  font-size: 12px;
  line-height: 12px;
  color: #808080;
}
.rightPanelSection .labelText {
  display: inline-flex;
  width: 140px;
  min-width: 140px;
  font-weight: 600;
}

.client {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}
@keyframes scaling {
  0% {
    transform: scale(1);
    background: var(--Teal-4, #6de5b9);
  }
  100% {
    transform: scale(3);
    background-color: rgba(245, 0, 182, 0);
  }
}

@keyframes phone {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.5);
  }
}
.phone {
  z-index: 2;
  background: var(--Teal-4, #6de5b9);
  animation: phone 1s var(--animation-curve) infinite alternate-reverse;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 0.001px #151515 solid;
}
.phone,
.circle1,
.circle2 {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: var(--Teal-4, #6de5b9);
  position: absolute;
  animation: scaling 2s var(--animation-curve) infinite;
}
.phone img {
  width: 9px;
}

.circle1 {
  animation-delay: 0s;
}
.circle2 {
  animation-delay: 1s;
}

.separator {
  display: inline-flex;
  width: 4px;
  height: 4px;
  background: #bbb;
  border-radius: 4px;
}
.callCard a {
  color: #007bff;
  font-family: "Sofia Sans";
}
.callCard * {
  font-family: "Sofia Sans";
}

.goToMapBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.goToMapBtn img {
  width: 14px;
  height: 14px;
  min-height: 14px;
  padding: 0;
}
.goToMapBtn button {
  min-height: 14px;
}

.goToMapBtn {
  color: #2b8efa !important;
}
.goToMap a {
  color: #333;
}

.confirmButton {
  width: 22px;
  height: 22px;
}
